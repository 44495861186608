import { Col, Row } from "@components/FlexBox/FlexBox";
import { Header } from "@components/Wrapper.style";

export const ErrorValidation = ({
  errorMessage,
}: {
  errorMessage: string[];
}) => {
  return (
    <>
      {errorMessage && errorMessage.length > 0 ? (
        <Row>
          <Col md={12}>
            {/* <Header style={{ justifyContent: "center" }} className="error"> */}
            <ul>
              {errorMessage.map((errorMsg, i) => {
                return (
                  <li className="error" key={i}>
                    {errorMsg || ""}
                  </li>
                );
              })}
            </ul>
            {/* </Header> */}
          </Col>
        </Row>
      ) : (
        ""
      )}
    </>
  );
};
