import { Button, SIZE } from "baseui/button";
import React from "react";
import VirtualizedList from "./Virtualized";
import noImage from "../../../@components/Image/noImage.jpg";

export const ProductListDropDown = (props) => {
  const listRef = React.useRef(null);
  const [cachedIndex, setCachedIndex] = React.useState(null);
  const children = Array.isArray(props.children)
    ? props.children
    : [props.children];
  const heightCalc = children.length * props.selectProps.itemSize();
  const customHeight = heightCalc >= 300 ? 300 : heightCalc;

  const currentIndex = Math.max(
    children.findIndex((child) => child.props.isFocused),
    0
  );
  // console.log(currentIndex);
  // THIS IS BUGGED
  const totalItem = props.selectProps.totalItem;
  const currentTotal = props.selectProps.currentTotal;

  // console.log(totalItem);
  // console.log(currentTotal);
  React.useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(currentIndex);
    }
  }, [currentIndex]);

  return (
    <VirtualizedList
      listRef={(ref) => {
        listRef.current = ref;
      }}
      width={100}
      height={customHeight}
      listStyle={{ width: "100%" }}
      items={children}
      hasNextPage={props.selectProps.hasNextPage}
      isNextPageLoading={props.selectProps.isNextPageLoading}
      loadNextPage={props.selectProps.loadNextPage}
      itemSize={props.selectProps.itemSize}
      totalItem={props.selectProps.totalItem}
      currentTotal={props.selectProps.currentTotal}
    >
      {({ index, style }) => {
        return children[index] ? (
          <div style={style}>
            <div style={{ display: "flex" }}>
              <img
                src={children[index]?.props?.data?.payload?.thumb || noImage}
                height="35"
                width="35"
                style={{ margin: "5px" }}
              />
              {children[index]}

              {/* <p> {children[index]?.props?.data?.payload?.newPrice}</p> */}
            </div>
          </div>
        ) : (
          <div style={style}>
            <span style={{ display: "flex", justifyContent: "center" }}>
              {totalItem > currentTotal ? (
                <Button
                  onClick={props.selectProps.loadNextPage}
                  size={SIZE.compact}
                  overrides={{
                    BaseButton: {
                      style: ({ $theme }) => {
                        return {
                          width: "40%",
                          // borderRadius: "30px",
                          margin: "0",
                        };
                      },
                    },
                  }}
                >
                  <small>Load More</small>
                </Button>
              ) : (
                "No more item"
              )}
            </span>
          </div>
        );
      }}
    </VirtualizedList>
  );
};
