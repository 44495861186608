import { WatsonApiInstance } from "@config/api.config";
import { AuthHeader } from "./auth.apiService";

export interface ICategoryGetAllApiOptions {
  id?: string;
  take?: any;
  page?: number;
  searchTerm?: string;
  department?: string;
} 

const headers = AuthHeader();
export const CategoryService = {
  getAll: (option: ICategoryGetAllApiOptions) =>
    WatsonApiInstance.get(
      `categories?department=${option.department || ""}&page=${ 
        option.page
      }&take=${option.take}&searchTerm=${option.searchTerm || ""}`,{ headers: AuthHeader() }
    ),

  create: (newModelData) =>
    WatsonApiInstance.post("categories/", newModelData, { headers: AuthHeader() }),

  getOne: (id) => WatsonApiInstance.get("categories/" + id, { headers: AuthHeader() }),

  deleteOne: (id) => WatsonApiInstance.delete("categories/" + id, { headers: AuthHeader() }),

  update: (editModelData, id) =>
    WatsonApiInstance.put(`categories/${id}`, editModelData, { headers: AuthHeader() }),
};
