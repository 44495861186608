import { Modal, ModalBody } from "baseui/modal";
import React from "react";

export const PreloaderModal = ({
  onClosePreloaderModal,
  isOpenPreloaderModalModal,
}) => {
  return (
    <Modal
      isOpen={isOpenPreloaderModalModal}
      //   Backdrop={true}
      unstable_ModalBackdropScroll={true}
      overrides={{
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
      }}
    >
      <ModalBody>
        <img height={400} width={400} src="/loading.gif" alt="loading" />
      </ModalBody>
    </Modal>
  );
};
