import { WatsonApiInstance } from "@config/api.config";
import { AuthHeader } from "./auth.apiService";

export interface ISupplierGetAllApiOptions {
  id?: string;
  take?: any;
  page?: number;
  searchTerm?: string;
  sourcingAssociate?: string;
}

const headers = AuthHeader();
export const SupplierService = {
  getAll: (option: ISupplierGetAllApiOptions) =>
    WatsonApiInstance.get(
      `suppliers?page=${option.page}&take=${option.take}&searchTerm=${
        option.searchTerm || ""
      }&sourcingAssociate=${option.sourcingAssociate}`,
      { headers: AuthHeader() }
    ),

  getAllTopSuppliers: () =>
    WatsonApiInstance.get(`top-suppliers?take=all`, { headers: AuthHeader() }),

  create: (newModelData) =>
    WatsonApiInstance.post("suppliers/", newModelData, {
      headers: AuthHeader(),
    }),

  getOne: (id) =>
    WatsonApiInstance.get("suppliers/" + id, { headers: AuthHeader() }),

  deleteOne: (id) =>
    WatsonApiInstance.delete("suppliers/" + id, { headers: AuthHeader() }),

  update: (editModelData, id) =>
    WatsonApiInstance.put(`suppliers/${id}`, editModelData, {
      headers: AuthHeader(),
    }),
  topSupplierPriorityUpdate: (editModelData) =>
    WatsonApiInstance.put(`top-suppliers/update-priority`, editModelData, {
      headers: AuthHeader(),
    }),

  area: () =>
    WatsonApiInstance.get("customer-areas", { headers: AuthHeader() }),

  getCustomerNewArea:() => WatsonApiInstance.get(`/shipping-areas?select=["name"]&take=all`),
};
