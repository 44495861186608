import { IGetAllApiOptions } from "@interfaces/Api.interface";
import { WatsonApiInstance } from "../../@config/api.config";
import { AuthHeader } from "./auth.apiService";

const headers = AuthHeader();
export const DepartmentService = {
  // getAll: (option: IGetAllApiOptions) =>
  //   WatsonApiInstance.get(
  //     `departments?page=${option.page}&take=${option.take}&searchTerm=${
  //       option.searchTerm || ""
  //     }`,
  //     { headers: AuthHeader() }
  //   ),

  getAll: (option: IGetAllApiOptions) =>
    WatsonApiInstance.get(
      `departments?page=${option.page}&take=${option.take}&searchTerm=${
        option.searchTerm || ""
      }`,
      { headers: AuthHeader() }
    ),

  getAllWithPriority: (option: IGetAllApiOptions) =>
    WatsonApiInstance.get(
      `departments/with-priority?page=${option.page}&take=${
        option.take
      }&searchTerm=${option.searchTerm || ""}`,
      { headers: AuthHeader() }
    ),

  create: (newDepartment) =>
    WatsonApiInstance.post("departments/", newDepartment, {
      headers: AuthHeader(),
    }),
  getOne: (id) =>
    WatsonApiInstance.get("departments/" + id, { headers: AuthHeader() }),
  deleteOne: (id) =>
    WatsonApiInstance.delete("departments/" + id, { headers: AuthHeader() }),

  update: (editDepartment, id) =>
    WatsonApiInstance.put(`departments/${id}`, editDepartment, {
      headers: AuthHeader(),
    }),
  updatePriority: (priority) =>
    WatsonApiInstance.put(`departments/update-priority`, priority, {
      headers: AuthHeader(),
    }),
};
