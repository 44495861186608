import Button, { SIZE } from "@components/Button/Button";
import DrawerBox from "@components/DrawerBox/DrawerBox";
import { Col, Row } from "@components/FlexBox/FlexBox";
import { FormFields } from "@components/FormFields/FormFields";
import { Image, ProfileImg } from "@pages/Products/ProductStyles/ProductStyles";
import React from "react";
import { AiFillMinusSquare, AiFillPlusCircle } from "react-icons/ai";
import { BsFillEyeFill, BsFillTrashFill } from "react-icons/bs";
import noImage from "../../../../@components/Image/noImage.jpg";

export const CartItemTable = ({
  cartItem,
  onDecrementCartItem,
  onIncrementCartItem,
  openProductDetailsDraware,
  onRemoveCart,
}) => {
  return (
    <DrawerBox>
      {cartItem.map((item, index) => (
        <Row key={index}>
          <Col md={1} sm={4} style={{ marginTop: "5px" }}>
            <FormFields>
              {index === 0 ? <small>Image</small> : null}
              <ProfileImg>
                <Image src={item.product?.thumb || item.image || noImage} />
              </ProfileImg>
            </FormFields>
          </Col>
          <Col md={3} sm={4} style={{ marginTop: "5px" }}>
            {index === 0 ? <small>Name</small> : null}
            <p>{item?.product?.name || null} </p>
          </Col>
          <Col md={1} sm={4} style={{ marginTop: "5px" }}>
            {index === 0 ? <small>Variant</small> : null}
            <p>{item?.variantOption?.name || null} </p>
          </Col>
          <Col md={2} sm={4} style={{ marginTop: "5px" }}>
            {index === 0 ? <small>Quantity</small> : null}
            <p>
              <Button
                size={SIZE.mini}
                onClick={() => onDecrementCartItem(item?.id, item?.quantity)}
                // endEnhancer={() => }
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => {
                      return {
                        padding: "5px 11px",
                      };
                    },
                  },
                }}
                disabled={item?.quantity > 1 ? false : true}
              >
                <AiFillMinusSquare />
              </Button>
              <span style={{ padding: "0 5px" }}>{item?.quantity || null}</span>
              <Button
                size={SIZE.mini}
                onClick={() => onIncrementCartItem(item.id, item.quantity)}
                // endEnhancer={() => }
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => {
                      return {
                        padding: "5px 11px",
                      };
                    },
                  },
                }}
              >
                <AiFillPlusCircle />
              </Button>
            </p>
          </Col>
          <Col md={1} sm={4} style={{ marginTop: "5px" }}>
            {index === 0 ? <small>Unit Price</small> : null}
            <p>{item?.variantOption?.mrp || null}</p>
          </Col>
          <Col md={1} sm={4} style={{ marginTop: "5px" }}>
            {index === 0 ? <small>Sub Total</small> : null}
            <p>{item?.variantOption?.mrp * item?.quantity || null} </p>
          </Col>
          <Col md={3} sm={4} style={{ marginTop: "5px" }}>
            {index === 0 ? <small>Action</small> : null}
            <p>
              <Button
                size={SIZE.mini}
                onClick={() => openProductDetailsDraware(item?.product?.id)}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => {
                      return {
                        backgroundColor: $theme.colors.primary700,
                      };
                    },
                  },
                }}
              >
                <BsFillEyeFill />
              </Button>
              <Button
                size={SIZE.mini}
                onClick={() => onRemoveCart(item.id)}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => {
                      return {
                        backgroundColor: $theme.colors.negative500,
                        marginLeft: "2px",
                      };
                    },
                  },
                }}
              >
                <BsFillTrashFill />
              </Button>
            </p>
          </Col>
        </Row>
      ))}
    </DrawerBox>
  );
};
