import "@components/Scrollbar/scrollbar.css";
import { BaseProvider } from "baseui";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import "overlayscrollbars/css/OverlayScrollbars.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-spring-modal/dist/index.css";
import { store } from "redux/store";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import { theme } from "./theme";
import "./theme/global.css";

function App() {
  const engine = new Styletron();

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <ToasterContainer
          autoHideDuration={3000}
          placement={PLACEMENT.topRight}
        >
          <BrowserRouter>
            {/* <Provider store={store}> */}
            <Routes />
            {/* </Provider> */}
          </BrowserRouter>
        </ToasterContainer>
      </BaseProvider>
    </StyletronProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
