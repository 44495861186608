import { InLineLoader } from "@components/InlineLoader/InlineLoader";
import OrderCerate from "@pages/Orders/OrderCreate/OrderCreate";
import { LocalStorageService } from "@services/utils/localStorage.utilservice";
import AuthProvider, { AuthContext } from "context/auth";
import { lazy, Suspense, useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  ADVANCEPAID,
  BRAND,
  BRANDR,
  CAMPAIGNCREATE,
  CAMPAIGNEDIT,
  CAMPAIGNS,
  CATEGORY,
  CMS,
  CMSCREATE,
  CMSEDIT,
  CONTACTS,
  COUPON,
  CREATEPRODUCT,
  CUSTOMERDETAILS,
  CUSTOMERS,
  DASHBOARD,
  DEPARTMENT,
  DUPLICATEPRODUCT,
  EMPLOYEES,
  FEATUREDPRODUCT,
  FEATUREDPRODUCTCREATE,
  FEATUREDPRODUCTEDIT,
  FORGOTPASSWORD,
  INVOICE,
  INVOICEPRINT, LIVECREATE,
  LIVEEDIT,
  LOGIN, ORDERCREATE,
  ORDERDETAILS,
  ORDERITEMSDELIVERED,
  ORDERITEMSRETURNED, PARENTORDER, PRODUCTPRIORITY,
  PRODUCTREQUISITION,
  PRODUCTREQUISITIONPRINT,
  PRODUCTS,
  PROFILE,
  Registration,
  SETTINGS,
  // RESELLER,
  // RESELLERDETAILS,
  STAFF,
  SUBCATEGORY,
  SUBCATEGORYPRIORITY, SUBORDER, SUPPLIERDETAILS,
  SUPPLIERS,
  TOPSUPPLERS,
  UPDATEPRODUCT,
  WITHDRAWREQUEST
} from "settings/constants";
// import ProductRequisitionPrint from "@pages/ProductRequisition/components/PRGeneratePrint.page";
const Products = lazy(() => import("@pages/Products/Products"));
const ProductPriority = lazy(
  () => import("@pages/Products/ProductPriority/ProductsPriority")
);

// const Resellers = lazy(() => import("@pages/Resellers/Resellers"));
// const ResellerDetails = lazy(
//   () => import("@pages/Resellers/components/ResellerDetails")
// );
// import ResellerDetails from "../Resellers/components/ResellerDetails";

const Suppliers = lazy(() => import("@pages/Suppliers/Suppliers"));
const TopSuppliers = lazy(
  () => import("@pages/Suppliers/components/SuppliersPriority")
);
const SupplierDetails = lazy(
  () => import("@pages/Suppliers/components/SupplierDetails")
);

const Staffs = lazy(() => import("@pages/Staffs/Staff"));
const Employees = lazy(() => import("@pages/Employees/Employees"));
const WarehouseOperators = lazy(
  () => import("@pages/WarehouseOperator/WareHouseOperators")
);

const CreateProduct = lazy(
  () => import("@pages/Products/proudctCreate/productCreate.page")
);
const ProductDuplicatePage = lazy(
  () => import("@pages/Products/ProductDuplication/producDuplicate.page")
);
const ProductUpdatePage = lazy(
  () => import("@pages/Products/productUpdate/productUpdate.page")
);
const Department = lazy(() => import("@pages/Department/Department"));
const Coupon = lazy(() => import("@pages/Coupon/Coupons"));

const Brand = lazy(() => import("@pages/Brand/Brand"));
const BrandWithRedux = lazy(() => import("@pages/Brand/BrandWithRedux"));
const Category = lazy(() => import("@pages/Category/Category"));
const SubCategory = lazy(() => import("@pages/SubCategory/SubCategory"));
const SubCategoryPriority = lazy(
  () => import("@pages/SubCategory/components/SubCategoryPriority")
);
const AdminLayout = lazy(() => import("@pages/Layout/Layout"));
const Dashboard = lazy(() => import("@pages/Dashboard/Dashboard"));

const DeliveredOrderItems = lazy(
  () => import("@pages/Orders/DeliveredOrderItems")
);
const ReturnedOrderItems = lazy(
  () => import("@pages/Orders/ReturnedOrderItems")
);
const CreateOrder = lazy(() => import("@pages/Orders/OrderCreate/OrderCreate"));
const SubOrders = lazy(() => import("@pages/Orders/SubOrders"));
const ParentOrders = lazy(() => import("@pages/Orders/ParentOrder"));
const OrderDetails = lazy(
  () => import("@pages/Orders/components/OrderDetails.page")
);
const ProductRequisition = lazy(
  () => import("@pages/ProductRequisition/ProductRequisition")
);
const ProductRequisitionPrint = lazy(
  () => import("@pages/ProductRequisition/components/PRGeneratePrint.page")
);
const InvoicePrint = lazy(
  () => import("@pages/Invoices/components/InvoicePrint.page")
);
const Invoice = lazy(() => import("@pages/Invoices/Invoices"));

const Campaign = lazy(() => import("@pages/campaigns/CampaignsTab"));

const CampaignCreate = lazy(
  () => import("@pages/campaigns/components/CampaignCreateForm")
);
const CampaignEdit = lazy(
  () => import("@pages/campaigns/components/CampaignEditForm")
);

const AllCMS = lazy(() => import("@pages/CMS/CMS"));

const CMSCreate = lazy(() => import("@pages/CMS/components/CMSCreate"));
const CMSEdit = lazy(() => import("@pages/CMS/components/CMSEdit"));

const FeaturedProduct = lazy(
  () => import("@pages/CMS/FeaturedProduct/FeaturedProduct")
);
const FeaturedProductCreate = lazy(
  () =>
    import("@pages/CMS/FeaturedProduct/components/FeaturedProductCreateForm")
);
const FeaturedProductEdit = lazy(
  () => import("@pages/CMS/FeaturedProduct/components/FeaturedProductEditForm")
);

const Contacts = lazy(() => import("@pages/Contact/contacts"));
const Customers = lazy(() => import("@pages/Customers/Customers"));
const CustomerDetails = lazy(
  () => import("@pages/Customers/components/CustomerDetails")
);
const WithdrawRequest = lazy(() => import("@pages/Withdraws/Withdraws"));
const AdvancedPayment = lazy(() => import("@pages/Payment/AdvancePayment"));

const Profile = lazy(() => import("@pages/Profile/Profile"));
const Settings = lazy(() => import("@pages/Settings/Settings"));

const Login = lazy(() => import("@pages/Login/Login"));

const RegistrationForm = lazy(() => import("@pages/Registration/Registration"));
const ForgotPassword = lazy(
  () => import("@pages/ForgotPassword/ForgotPassword")
);

const NotFound = lazy(() => import("@pages/NotFound/NotFound"));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

const checkAuth = () => {
  const token = LocalStorageService.get("reshop_accessToken");
  // console.log(token);
  if (!token) {
    return false;
  }
  return true;
};

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);
  // console.log(isAuthenticated);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated && checkAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CMS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AllCMS />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CMSCREATE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CMSCreate />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CMSEDIT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CMSEdit />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTPRIORITY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductPriority />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CREATEPRODUCT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CreateProduct />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={UPDATEPRODUCT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductUpdatePage />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={DUPLICATEPRODUCT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductDuplicatePage />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={DEPARTMENT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Department />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COUPON}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Coupon />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={BRAND}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Brand />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={BRANDR}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <BrandWithRedux />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Category />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SUBCATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SubCategory />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SUBCATEGORYPRIORITY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SubCategoryPriority />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SUBORDER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SubOrders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PARENTORDER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ParentOrders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERCREATE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <OrderCerate />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERDETAILS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <OrderDetails />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERITEMSDELIVERED}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <DeliveredOrderItems />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERITEMSRETURNED}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ReturnedOrderItems />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTREQUISITION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductRequisition />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVOICE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Invoice />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVOICEPRINT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <InvoicePrint />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTREQUISITIONPRINT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductRequisitionPrint />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERDETAILS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CustomerDetails />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SUPPLIERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Suppliers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={TOPSUPPLERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <TopSuppliers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          {/* <PrivateRoute path={LIVE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Live />
              </Suspense>
            </AdminLayout>
          </PrivateRoute> */}
          <PrivateRoute path={FEATUREDPRODUCTCREATE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <FeaturedProductCreate />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={FEATUREDPRODUCTEDIT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <FeaturedProductEdit />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={FEATUREDPRODUCT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <FeaturedProduct />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LIVECREATE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                {/* <LiveCreate /> */}
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={LIVEEDIT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                {/* <LiveEdit /> */}
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SUPPLIERDETAILS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SupplierDetails />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STAFF}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Staffs />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WITHDRAWREQUEST}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WithdrawRequest />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={EMPLOYEES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Employees />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CAMPAIGNS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Campaign />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CAMPAIGNCREATE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CampaignCreate />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CAMPAIGNEDIT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CampaignEdit />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CONTACTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Contacts />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ADVANCEPAID}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AdvancedPayment />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PROFILE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Profile />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route path={FORGOTPASSWORD}>
            <ForgotPassword />
          </Route>
          <Route path={Registration}>
            <RegistrationForm />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
