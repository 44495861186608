import { WatsonApiInstance } from "@config/api.config";
import { AuthHeader } from "./auth.apiService";

export interface IResellerGetAllApiOptions {
  id?: string;
  take?: any;
  page?: number;
  searchTerm?: string;
}

const headers = AuthHeader();
export const CustomerService = {
  getAll: (option: IResellerGetAllApiOptions) =>
    WatsonApiInstance.get(
      `customers?page=${option.page}&take=${option.take}&searchTerm=${
        option.searchTerm || ""
      }`,
      { headers: AuthHeader() }
    ),

  getCustomerAddressById: (id) =>
    WatsonApiInstance.get(`customer-address?take=all&customer=${id}`, {
      headers: AuthHeader(),
    }),
  create: (newModelData) =>
    WatsonApiInstance.post("customers/", newModelData, {
      headers: AuthHeader(),
    }),
  createAddress: (newModelData) =>
    WatsonApiInstance.post("customer-address/", newModelData, {
      headers: AuthHeader(),
    }),
  newCreateAddress: (newModelData) =>
  WatsonApiInstance.post("customer-address/", newModelData, {
    headers: AuthHeader(),
  }),  
  getOne: (id) =>
    WatsonApiInstance.get("customers/" + id, { headers: AuthHeader() }),

  deleteOne: (id) =>
    WatsonApiInstance.delete("customers/" + id, { headers: AuthHeader() }),

  update: (editModelData, id) =>
    WatsonApiInstance.put(`customers/${id}`, editModelData, {
      headers: AuthHeader(),
    }),
};
