import { rsToaster } from "@components/toaster/toaster";
import { SubCategoryService } from "@services/apiServices/subCategory.apiService";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { catchError } from "rxjs/operators";
import { MenuList } from "../../../@components/Select/InfiniteScroll/MenuList";

interface IModelPagination {
  currentPage: number;
  take: number;
  totalPage: number;
  totalData: number;
}
interface ISubCategorySelect {
  loading?: Function;
  setSelectedSubCategory?: Function;
  selectedSubCategory?: any;
  category?: any;
}

function SubCategorySelect({
  loading,
  selectedSubCategory,
  setSelectedSubCategory,
  category,
}: ISubCategorySelect) {
  const [response, setResponse] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [
    modelPagination,
    setModelPagination,
  ] = React.useState<IModelPagination>({
    currentPage: 0,
    take: 10,
    totalPage: 0,
    totalData: 0,
  });
  let dd;
  const [perPageTake, setPerPageTake] = useState([{ title: "10", value: 10 }]);

  useEffect(() => {
    getSubCategoryData();
  }, [category]);

  const mockService = () => {
    // setModelPagination({
    //   ...modelPagination,
    //   currentPage: modelPagination.currentPage + 1,
    // });
    // setIsLoading(true);
  };

  const getSubCategoryData = () => {
    dd = SubCategoryService.getAll({
      // page: modelPagination.currentPage || 1,
      take: "all",
      category: category[0]?.id || "",
    }).subscribe(
      (res: any) => {
        let SubCategoryData = res.payload;
        setModelPagination({
          ...modelPagination,
          totalData: res.total,
          take: res.take,
          currentPage: res.page,
          totalPage: Math.ceil(res.total / res.take),
        });
        setResponse(SubCategoryData);
        // setResponse([...response, ...SubCategoryData]);
      },
      (error) => {
        // rsToaster.error(`${error.message}`);
      }
    );
  };

  const onChangeSelectOption = (value) => {
    if (value) {
      const data = value.payload;
      const dd = [{ id: data?.id, name: data?.name }];
      setSelectedSubCategory(dd);
    } else {
      setSelectedSubCategory([]);
    }
    loading(true);
  };

  const onOptions = response.map((item) => {
    return {
      value: item.name,
      label: item.name,
      payload: {
        ...item,
      },
    };
  });
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      options={onOptions}
      hasNextPage={true}
      isNextPageLoading={isLoading}
      loadNextPage={mockService}
      itemSize={() => 50}
      totalItem={modelPagination.totalData || 0}
      currentTotal={response.length || 0}
      components={{
        MenuList,
      }}
      onChange={onChangeSelectOption}
      // onClear={() => console.log("ddd")}
      isClearable
    />
  );
}

export default SubCategorySelect;
