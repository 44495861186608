import { AxiosRequestConfig } from 'axios';
import { Axios,  } from 'axios-observable';
import { ENV } from './ENV.config';

const axios = Axios.create({
	baseURL: ENV.WatsonApiEndpoint,
});

axios.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		// Do something before request is sent

		config.headers.AJKE_NO_CACHE = config?.method === 'get' ? true : false;
		return config;
	},
	(error) => {
		// Do something with request error
		return Promise.reject(error);
	}
);
axios.interceptors.response.use(
	(res: any) => {
		// console.log('from config', res);
		return { ...res.data, ...{ status: res.status } };
	},
	(error) => {
		// console.log('from config', error);

		if (error.response) {
			return Promise.reject({
				...error.response.data,
				...{ status: error.response.data.statusCode || error.status },
			});
		} else {
			return Promise.reject({
				success: false,
				statusCode: 404,
				status: 404,
				message: 'server not responding',
				errorMessages: ['server not responding'],
			});
		}
	}
);

const ExcelAxios = Axios.create({
	baseURL: ENV.ExcelDownloadEndpoint,
});

export const WatsonApiInstance = axios;
export const ImageSrc = ENV.ImageStaticEndpoint;
export const ExcelDownload = ExcelAxios;
