import { LocalStorageService } from "@services/utils/localStorage.utilservice";
import React from "react";

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = LocalStorageService.get("ajke_accessToken");

  return token ? true : false;
};


const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());

  function authenticate({ token, refreshToken }, cb) {
    // console.log(phoneNumber)
    makeAuthenticated(true);
    LocalStorageService.set("ajke_accessToken", token);
    LocalStorageService.set("refreshToken", refreshToken);
    cb(); // fake async
  }

  function signout(cb) {
    makeAuthenticated(false);
    LocalStorageService.delete("ajke_accessToken");
    LocalStorageService.delete("refreshToken");
    setTimeout(cb, 100);
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
