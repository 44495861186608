import { WatsonApiInstance } from "@config/api.config";
import { AuthHeader } from "./auth.apiService";

export interface IOrderGetAllApiOptions {
  id?: string;
  orderId?: string;
  customerId?: string;
  take?: any;
  page?: Number;
  searchTerm?: string;
  customer?: any;
  parentOrderId?: any;
}

const headers = AuthHeader();
export const OrdersService = {
  getAllParentOrder: (option: IOrderGetAllApiOptions) =>
    WatsonApiInstance.get(
      `parent-orders?customer=${option.customer || ""}&page=${
        option.page
      }&take=${option.take}&parentOrderNumber=${option.searchTerm}`,
      { headers: AuthHeader() }
    ),
  getAllSubOrder: (option: IOrderGetAllApiOptions) =>
    WatsonApiInstance.get(
      `orders?customer=${option.customer || ""}&page=${option.page}&take=${
        option.take
      }&orderNumber=${option.searchTerm}&parentOrder=${option.parentOrderId}`,
      { headers: AuthHeader() }
    ),
  getAllOrderItemsDelivered: (option: IOrderGetAllApiOptions) =>
    WatsonApiInstance.get("order-items?operationalOrderStatus=Delivered", {
      headers: AuthHeader(),
    }),
  getAllOrderItemsReturned: (option: IOrderGetAllApiOptions) =>
    WatsonApiInstance.get("order-items?operationalOrderStatus=Returned", {
      headers: AuthHeader(),
    }),
  getAllOrderItemByOrderId: (option: IOrderGetAllApiOptions) =>
    WatsonApiInstance.get(`orders/all-order-items/${option.orderId}`, {
      headers: AuthHeader(),
    }),
  getAllOrderItemByCustomer: (option: IOrderGetAllApiOptions) =>
    WatsonApiInstance.get(
      `orders?customer=${option.customerId}&page=${option.page}&take=${option.take}`,
      {
        headers: AuthHeader(),
      }
    ),
  create: (newModelData) =>
    WatsonApiInstance.post("orders/", newModelData, { headers: AuthHeader() }),
  createNew: (newModelData) =>
    WatsonApiInstance.post("orders/v2", newModelData, { headers: AuthHeader() }),

  createPR: (newModelData) =>
    WatsonApiInstance.post("prs/", newModelData, { headers: AuthHeader() }),

  getOne: (id) =>
    WatsonApiInstance.get("orders/" + id, { headers: AuthHeader() }),

  deleteOne: (id) =>
    WatsonApiInstance.delete("orders/" + id, { headers: AuthHeader() }),

  deleteParentOrder: (id) =>
    WatsonApiInstance.delete("parent-orders/" + id, { headers: AuthHeader() }),

  orderItemStatusUpdate: (orderStatus, id) =>
    WatsonApiInstance.put(`order-items/${id}`, orderStatus, {
      headers: AuthHeader(),
    }),
  update: (editModelData, id) =>
    WatsonApiInstance.put(`orders/${id}`, editModelData, {
      headers: AuthHeader(),
    }),
};
