import { SIZE } from "@components/Button/Button";
import { FormFields, FormLabel } from "@components/FormFields/FormFields";
import Input from "@components/Input/Input";
import { rsToaster } from "@components/toaster/toaster";
import { CustomerService } from "@services/apiServices/Customer.apiService";
import { Button } from "baseui/button";
import { Card } from "baseui/card";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface ICustomerCreateForm {
  setIsLoadingCustomer?: Function;
  setIsActiveCustomerForm?: Function;
  setSelectedCustomer?: Function;
  setIsLoadingCart: Function;
  onCancelCustomerForm: any;
}
export const CustomerCreateForm = ({
  setIsLoadingCustomer,
  setIsActiveCustomerForm,
  setSelectedCustomer,
  setIsLoadingCart,
  onCancelCustomerForm,
}: ICustomerCreateForm) => {
  const { register, handleSubmit } = useForm();
  const [myIsSubmitting, setMyIsSubmitting] = useState(false);

  const onSubmit = ({ firstName, lastName, phoneNumber, password }) => {
    setIsLoadingCustomer(false);
    setMyIsSubmitting(true);
    const customerData = {
      firstName: firstName || "",
      lastName: lastName || "",
      phoneNumber: phoneNumber || "",
      password: password || "",
    };

    CustomerService.create(customerData).subscribe(
      (res: any) => {
        const data = res.payload;
        setSelectedCustomer([
          {
            id: data.id || "",
            name: data.name || "",
            phoneNumber: data?.phoneNumber || "",
            image: data?.image || "",
          },
        ]);
        setIsLoadingCustomer(true);
        setIsActiveCustomerForm(false);
        setIsLoadingCart(true);
        rsToaster.success(res.message);
        setMyIsSubmitting(false);
      },
      (error) => {
        setIsActiveCustomerForm(true);
        setIsLoadingCustomer(false);
        setMyIsSubmitting(false);

        rsToaster.success(error.devMessage);
      }
    );
  };

  return (
    <Card title="Customer Create Form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFields>
          <FormLabel>First Name</FormLabel>
          <Input
            size={SIZE.mini}
            placeholder="First Name"
            name="firstName"
            inputRef={register({ required: true })}
          />
        </FormFields>

        <FormFields>
          <FormLabel>Last Name</FormLabel>
          <Input
            size={SIZE.mini}
            placeholder="Last Name"
            name="lastName"
            inputRef={register({ required: true })}
          />
        </FormFields>

        <FormFields>
          <FormLabel>Phone Number</FormLabel>
          <Input
            size={SIZE.mini}
            placeholder="Phone Number"
            name="phoneNumber"
            type="number"
            inputRef={register({ required: true })}
          />
        </FormFields>
        <FormFields>
          <FormLabel>Password</FormLabel>
          <Input
            size={SIZE.mini}
            placeholder="Password"
            name="password"
            type="password"
            inputRef={register({ required: true })}
          />
        </FormFields>
        <div style={{ display: "flex" }}>
          <Button
            overrides={{
              BaseButton: {
                style: { width: "48%", margin: "5px" },
              },
            }}
            size={SIZE.mini}
            disabled={myIsSubmitting}
          >
            Create Customer
          </Button>
          <Button
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    backgroundColor: $theme.colors.negative500,
                    width: "48%",
                    margin: "5px",
                  };
                },
              },
            }}
            size={SIZE.mini}
            onClick={onCancelCustomerForm}
          >
            <small>Cancel</small>
          </Button>
        </div>
      </form>
    </Card>
  );
};
