import { rsToaster } from "@components/toaster/toaster";
import { CategoryService } from "@services/apiServices/category.apiService";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { MenuList } from "../../../@components/Select/InfiniteScroll/MenuList";

interface IModelPagination {
  currentPage: number;
  take: number;
  totalPage: number;
  totalData: number;
}
interface ICategorySelect {
  loading?: Function;
  setSelectedCategory?: Function;
  selectedCategory?: any;
  department?: any;
  isLoadingCategory?: boolean;
}

function CategorySelect({
  loading,
  selectedCategory,
  setSelectedCategory,
  department = [],
  isLoadingCategory,
}: ICategorySelect) {
  const [response, setResponse] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [
    modelPagination,
    setModelPagination,
  ] = React.useState<IModelPagination>({
    currentPage: 0,
    take: 10,
    totalPage: 0,
    totalData: 0,
  });

  const [perPageTake, setPerPageTake] = useState([{ title: "10", value: 10 }]);

  useEffect(() => {
    // if (department.length > 0) {
    //   setResponse([]);
    // }
    getCategoryData();
  }, [department]);

  const mockService = () => {
    // setModelPagination({
    //   ...modelPagination,
    //   currentPage: modelPagination.currentPage + 1,
    // });
    // setIsLoading(true);
  };

  const getCategoryData = () => {
    CategoryService.getAll({
      // page: modelPagination.currentPage || 1,
      take: "all",
      department: department[0]?.id || "",
    }).subscribe(
      (res: any) => {
        let CategoryData = res.payload;
        setModelPagination({
          ...modelPagination,
          totalData: res.total,
          take: res.take,
          currentPage: res.page,
          totalPage: Math.ceil(res.total / res.take),
        });
        setResponse(CategoryData);
      },
      (error) => {
        // rsToaster.error(`${error.message}`);
      }
    );
  };

  const onChangeSelectOption = (value) => {
    if (value) {
      const data = value.payload;
      const dd = [data];
      setSelectedCategory(dd);
    } else {
      setSelectedCategory([]);
    }
    loading(true);
  };

  const onOptions = response.map((item) => {
    return {
      value: item.name,
      label: item.name,
      payload: {
        ...item,
      },
    };
  });
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      // name="color"
      options={onOptions}
      hasNextPage={true}
      isNextPageLoading={isLoading}
      loadNextPage={mockService}
      itemSize={() => 50}
      components={{
        MenuList,
      }}
      onChange={onChangeSelectOption}
      totalItem={modelPagination.totalData || 0}
      currentTotal={response.length || 0}
      isClearable
    />
  );
}

export default CategorySelect;
