import { SIZE } from "@components/Button/Button";
import { Col } from "@components/FlexBox/FlexBox";
import { Button } from "baseui/button";
import React, { useRef, useState } from "react";
import { Row } from "react-flexbox-grid";
import CategorySelect from "./ScrollSelect/CategorySelect";
import DepartmentSelect from "./ScrollSelect/DepartementSelect";
import ProductSelect from "./ScrollSelect/ProductSelect";
import SubCategorySelect from "./ScrollSelect/SubCategorySelect";
interface IProductFilterSelect {
  loading?: Function;
  selectedSubCategory?: any;
  setSelectedSubCategory?: any;
  category?: any;
  onSelectedProduct?: Function;
  isActiveButton?: boolean;
}
export const ProductFilterSelect = ({
  onSelectedProduct,
  isActiveButton = false,
}: IProductFilterSelect) => {
  const [selectedDepartment, setSelectedDepartment] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>([]);
  const [isAddProduct, setIsAddProduct] = useState(true);
  // const loading = (bool) => {};
  const productRef: any = useRef();
  const loading = (bool) => {
    if (bool) {
      productRef.current.restProductData();
    }
  };
  return (
    <div style={{ marginBottom: "10px" }}>
      {isAddProduct ? (
        <Row style={{ margin: "auto" }}>
          <Col md={6} sm={12}>
            <small>Department</small>
            <DepartmentSelect
              setSelectedDepartment={setSelectedDepartment}
              loading={loading}
            />
          </Col>
          <Col md={6} sm={12}>
            <small>Category</small>
            <CategorySelect
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              loading={loading}
              department={selectedDepartment}
            />
          </Col>
          <Col md={4} sm={12}>
            <small>Sub Category</small>
            <SubCategorySelect
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
              loading={loading}
              category={selectedCategory}
            />
          </Col>
          <Col md={8} sm={12}>
            <small>Product</small>
            <ProductSelect
              ref={productRef}
              onSelectedProduct={onSelectedProduct}
              department={selectedDepartment}
              category={selectedCategory}
              subCategory={selectedSubCategory}
            />
          </Col>
          <Col style={{ marginTop: "10px" }} md={6} sm={12}>
            <Button
              type="button"
              onClick={() => setIsAddProduct(false)}
              size={SIZE.mini}
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => {
                    return {
                      backgroundColor: $theme.colors.negative500,
                      color: "white",
                      width: "20%",
                    };
                  },
                },
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      ) : (
        <Button
          type="button"
          onClick={() => setIsAddProduct(true)}
          size={SIZE.mini}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: "50%",
                borderTopLeftRadius: "3px",
                borderTopRightRadius: "3px",
                borderBottomRightRadius: "3px",
                borderBottomLeftRadius: "3px",
              }),
            },
          }}
        >
          Add New Product
        </Button>
      )}
    </div>
  );
};
