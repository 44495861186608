import { WatsonApiInstance } from "@config/api.config";
import { AuthHeader } from "./auth.apiService";
const headers = AuthHeader();
export interface ISubCategoryGetAllApiOptions {
  id?: string;
  take?: any;
  page?: number;
  searchTerm?: string;
  category?: string;
}

export const SubCategoryService = {
  getAll: (option: ISubCategoryGetAllApiOptions) =>
    WatsonApiInstance.get(
      `subCategories?category=${option.category || ""}&page=${
        option.page
      }&take=${option.take}&searchTerm=${option.searchTerm || ""}`,
      { headers: AuthHeader() }
    ),

  create: (newModelData) =>
    WatsonApiInstance.post("subCategories/", newModelData, {
      headers: AuthHeader(),
    }),

  getOne: (id) =>
    WatsonApiInstance.get("subCategories/" + id, { headers: AuthHeader() }),

  deleteOne: (id) =>
    WatsonApiInstance.delete("subCategories/" + id, { headers: AuthHeader() }),

  update: (editModelData, id) =>
    WatsonApiInstance.put(`subCategories/${id}`, editModelData, {
      headers: AuthHeader(),
    }),
  priorityUpdate: (editModelData,) =>
    WatsonApiInstance.put(`subCategories/update-priority`, editModelData, {
      headers: AuthHeader(),
    }),
};
