import { styled } from "baseui";

export const ThumbsContainer = styled("aside", () => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: "16px",
}));

export const Thumb = styled("div", ({ $theme }) => ({
  ...$theme.borders.borderEA,
  display: "inline-flex",
  borderRadius: "2px",
  // marginBottom: "8px",
  // marginRight: "8px",
  width: "100px",
  height: "100px",
  padding: "4px",
  boxSizing: "border-box",
}));

export const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};
export const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const ProductsRow = styled("div", ({ $theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "25px",
  backgroundColor: $theme.colors.backgroundF7,
  position: "relative",
  zIndex: "1",

  "@media only screen and (maxWidth: 767px)": {
    marginLeft: "-7.5px",
    marginRight: "-7.5px",
    marginTop: "15px",
  },
}));

// export const Col = withStyle(Column, () => ({
//   "@media only screen and (max-width: 767px)": {
//     marginBottom: "20px",

//     ":last-child": {
//       marginBottom: 0,
//     },
//   },
// }));

// export const Row = withStyle(Rows, () => ({
//   "@media only screen and (min-width: 768px) and (max-width: 991px)": {
//     alignItems: "center",
//   },
// }));

export const ProductCardWrapper = styled("div", () => ({
  height: "100%",
}));

export const LoaderWrapper = styled("div", () => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexWrap: "wrap",
}));

export const LoaderItem = styled("div", () => ({
  width: "25%",
  padding: "0 15px",
  marginBottom: "30px",
}));

export const ProfileImg = styled("div", () => ({
  width: "60px",
  height: "50px",
  display: "flex",
  borderRadius: "10%",
  border: "2px solid #ffffff",
  overflow: "hidden",
  cursor: "pointer",
  flexShrink: "0",
}));
export const Image = styled("img", () => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));
