import { Breadcrumb } from "@components/Breadcumb/Breadcumb";
import Button, { KIND, SIZE } from "@components/Button/Button";
import { FormFields, FormLabel } from "@components/FormFields/FormFields";
import { Col, Grid, Row } from "@components/FlexBox/FlexBox";
import Input from "@components/Input/Input";
import Select from "@components/Select/Select";
import { rsToaster } from "@components/toaster/toaster";
import { Header } from "@components/Wrapper.style";
import { ButtonGroup } from "@pages/DrawerItems/DrawerItems.style";
import { ErrorValidation } from "@pages/ErrorValidation/ErrorValidation";
import { PreloaderModal } from "@pages/PreloaderModal/PreloaderModal";
import { ProductFilterSelect } from "@pages/Select/ProductFilterSelecte";
import CustomerSelect from "@pages/Select/ScrollSelect/CustomerSelect";
import { CartsService } from "@services/apiServices/cart.apiService";
import { CustomerService } from "@services/apiServices/Customer.apiService";
import { OrdersService } from "@services/apiServices/order.apiService";
import { styled } from "baseui";
import { Card, StyledAction, StyledBody } from "baseui/card";
import {
  StyledRoot,
  StyledTable,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyRow,
} from "baseui/table-semantic";
import { useDrawerDispatch } from "context/DrawerContext";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiCartAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { PARENTORDER, SUBORDER } from "settings/constants";
import noImage from "../../../@components/Image/noImage.jpg";
import { CartItemTable } from "./components/CartItemTable";
import { CartTable } from "./components/CartTable";
import { AddressAddressCreate } from "./components/CustomerAddressCreate";
import { CustomerCreateForm } from "./components/CustomerCreate";
import { SupplierService } from "@services/apiServices/Supplier.apiService";

const CustomerImg = styled("div", () => ({
  // width: "100px",
  height: "100px",
  display: "flex",
  justifyContent: "center",
  borderRadius: "10%",
  padding: "2px !important",
}));
export const Image = styled("img", () => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
}));
type Props = any;
const OrderCerate: React.FC<Props> = (props) => {
  const history = useHistory();
  const [deliveryAmount, setDeliveryAmount] = useState<any[]>([]);

  const [selectedProduct, setSelectedProduct] = React.useState<any>();
  const dispatch = useDrawerDispatch();

  const { register, handleSubmit } = useForm();
  const openProductDetailsDraware = useCallback(
    (data) =>
      dispatch({
        type: "OPEN_DRAWER",
        drawerComponent: "PRODUCT_DETAILS",
        data,
      }),
    [dispatch]
  );

  const [isLoadingCustomer, setIsLoadingCustomer] = useState(true);
  const [isActiveCustomerForm, setIsActiveCustomerForm] =
    useState<boolean>(false);
  const [myIsSubmitting, setMyIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [resetCartForm, setResetCartForm] = useState<any>(true);
  const [isActiveAddressForm, setIsActiveAddressForm] = useState<any>(false);
  const [cartData, setCartData] = useState<any>();
  const [cartItem, setCartItem] = useState<any>([]);
  const [customerAddresses, setCustomerAddresses] = useState<any>([]);
  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [preloader, setPreloader] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<any[]>([]);
  const [shippingAddressId, setShippingAddressId] = useState(null);
  const [billingAddressId, setBillingAddressId] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState("");
  const [areaDeliveryCharge, setAreaDeliveryCharge] = useState("");
  const onSubmitAddToCart = ({ quantity }) => {
    setPreloader(true);
    setMyIsSubmitting(true);
    const CartItem = {
      customer: selectedCustomer[0].id || null,
      quantity: quantity || null,
      variantOption: selectedVariant[0].id || null,
    };
    onAddToCart(CartItem);
  };

  const onAddToCart = (cartItem) => {
    CartsService.createCartItems(cartItem).subscribe(
      (res: any) => {
        console.log(res);
        setResetCartForm(false);
        rsToaster.success(res.message);
        setMyIsSubmitting(false);
        setPreloader(false);
        setIsLoadingCart(true);
        setSelectedVariant([]);
        setErrorMessage([]);
      },
      (error) => {
        setPreloader(false);
        setMyIsSubmitting(false);
        setErrorMessage(error?.errorMessages);
        rsToaster.error(error?.errorMessages[0]);
      }
    );
  };

  const onOrderGenerate = () => {
    const orderData = {
      cart: cartData?.id || null,
      billing: billingAddressId || null,
      shipping: shippingAddressId || null,
      customer: selectedCustomer[0]?.id || null,
      deliveryCharge: parseInt(areaDeliveryCharge) || "0",
    };
    if (orderData.customer === null) {
      rsToaster.error("Customer Is not assign");
      return;
    }
    if (orderData.cart === null) {
      rsToaster.error("Cart Is not assign");
      return;
    }
    if (orderData.shipping === null) {
      rsToaster.error("Shipping Address is not selected ");
      return;
    }
    if (orderData.billing === null) {
      rsToaster.error("Billing Address is not selected");
      return;
    }
    setMyIsSubmitting(true);

    // OrdersService.create(orderData).subscribe(
    OrdersService.createNew(orderData).subscribe(
      (res: any) => {
        const customerId = res?.payload[0]?.customer;
        if (customerId) {
          history.push(`/customer-details/${customerId}`);
        } else {
          history.push(SUBORDER);
        }
        rsToaster.success("Order generate Success");
        setMyIsSubmitting(false);
      },
      (error) => {
        setMyIsSubmitting(false);
        rsToaster.error("Something went wrong");
      }
    );
  };

  useEffect(() => {
    if (isLoadingCart) {
      getCartData();
      getCustomerAddress();
    }
  }, [isLoadingCart]);

  const getCustomerAddress = () => {
    if (selectedCustomer.length > 0) {
      CustomerService.getCustomerAddressById(selectedCustomer[0].id).subscribe(
        (res: any) => {
          setCustomerAddresses(res?.payload);
        },
        (error) => {
          rsToaster.error(`${error.message}`);
        }
      );
    }
  };

  const getSingleAreaData = (id) => {
    SupplierService.getCustomerNewArea().subscribe(
      (res: any) => {
        let allArea = res.payload;
        let areaCharge = allArea.filter((area) => area.id === id);
        setAreaDeliveryCharge(areaCharge[0]?.deliveryCharge.amount);
      },
      (error) => {}
    );
  };

  const getCartData = () => {
    if (selectedCustomer.length > 0) {
      CartsService.getAllCartDataByCustomerId({
        customerId: selectedCustomer[0].id,
      }).subscribe(
        (res: any) => {
          setCartData(res.payload);
          setCartItem(res?.payload?.cartItems || []);
          setIsLoadingCart(false);
        },
        (error) => {
          rsToaster.error(`${error.message}`);
          setIsLoadingCart(false);
        }
      );
    }
  };
  const changeCustomerHandler = (value) => {
    setIsLoadingCart(false);

    if (value.length > 0) {
      setSelectedCustomer(value);
      setIsActiveCustomerForm(false);
      setIsLoadingCart(true);
    } else {
      setSelectedCustomer([]);
      setCartData(null);
      setCartItem([]);
      setCustomerAddresses([]);
      setShippingAddressId(null);
      setBillingAddressId(null);
      setIsLoadingCart(false);
    }
    setCouponError("");
  };
  const onSelectedProduct = (value) => {
    if (value[0]?.stock === 0) {
      alert("This product out of Stock");
      return;
    }

    if (value.length > 0) {
      setSelectedProduct(value[0]);
      setResetCartForm(true);
      setSelectedVariant([]);
    }
  };

  const variantOptionChange = (value) => {
    setSelectedVariant(value.value);
  };
  const onChangeQuantity = (event) => {
    console.log(event.target.value);
  };
  const onDecrementCartItem = (id, qty) => {
    const newQty = qty - 1;
    const data = {
      quantity: newQty,
    };
    CartsService.updateCartItems(data, id).subscribe(
      (res) => {
        rsToaster.success("Quantity Update Successful");
        setIsLoadingCart(true);
      },
      (error) => {
        rsToaster.error("Something went Wrong");
        setIsLoadingCart(false);
      }
    );
  };
  const onIncrementCartItem = (id, qty) => {
    const newQty = qty + 1;
    const data = {
      quantity: newQty,
    };
    CartsService.updateCartItems(data, id).subscribe(
      (res) => {
        rsToaster.success("Quantity Update Successful");
        setIsLoadingCart(true);
      },
      (error) => {
        rsToaster.error("Something went Wrong");
        setIsLoadingCart(false);
      }
    );
  };
  const onRemoveCart = (id) => {
    CartsService.deleteCartItem(id).subscribe(
      (res) => {
        rsToaster.success("Cart Item Successful");
        setIsLoadingCart(true);
      },
      (error) => {
        rsToaster.error("Something went Wrong");
        setIsLoadingCart(true);
      }
    );
  };

  const onShippingAddress = (id: string, shippingAreaId: any) => {
    getSingleAreaData(shippingAreaId);
    setShippingAddressId(id);
  };
  const onBillingAddress = (id: string) => {
    setBillingAddressId(id);
  };
  const AddNewAddressForm = () => {
    setIsActiveAddressForm(!isActiveAddressForm);
  };
  const onAddNewCustomer = () => {
    setIsActiveCustomerForm(!isActiveCustomerForm);
  };
  const onCancelCustomerForm = () => {
    setIsActiveCustomerForm(false);
    if (!selectedCustomer.length) {
      setSelectedCustomer([]);
      setCartData(null);
      setCartItem([]);
      setCustomerAddresses([]);
      setShippingAddressId(null);
      setBillingAddressId(null);
      setIsLoadingCart(false);
    }
  };
  const applyCoupon = () => {
    // console.log(cartData?.id);
    const data = {
      cart: cartData?.id,
      coupon: coupon,
      customer: selectedCustomer[0]?.id || null,
    };
    CartsService.couponApply(data).subscribe(
      (res: any) => {
        rsToaster.success("Coupon Added Successful");
        setIsLoadingCart(true);
        setCoupon("");
        setCouponError("");
      },
      (error) => {
        setCouponError(error?.errorMessages[0] || "something went Wrong");
        rsToaster.error(error?.errorMessages[0] || "something went wrong");
        setCoupon("");
      }
    );
  };
  const addToCartProduct = () => {
    return (
      <form onSubmit={handleSubmit(onSubmitAddToCart)}>
        <Card
          // headerImage={selectedProduct?.thumb || noImage}
          title={selectedProduct.name}
          // overrides={{
          //   Root: { style: { width: "100%" } } ,
          //   HeaderImage: {
          //     style: ({ $theme }) => ({

          //     })
          //   }
          // }}
        >
          <StyledBody>
            <div style={{ width: "100%", height: "10rem" }}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                src={selectedProduct?.thumb || noImage}
                alt=""
              />
            </div>
            <Row>
              <Col md={6}>
                <span>Quantity</span>
                <Input
                  type="number"
                  onChange={onChangeQuantity}
                  min="1"
                  max={selectedProduct?.stock}
                  size={SIZE.mini}
                  name="quantity"
                  inputRef={register({ required: true })}
                />
              </Col>
              <Col md={6}>
                <span>{selectedProduct?.variantName || null}</span>
                <Select
                  size={SIZE.mini}
                  options={selectedProduct?.variantOptions || null}
                  labelKey="name"
                  value={selectedVariant}
                  placeholder="Variant"
                  onChange={variantOptionChange}
                  required
                />
              </Col>
            </Row>
          </StyledBody>
          <StyledAction>
            <Button
              disabled={selectedCustomer.length > 0 ? false : true}
              overrides={{
                BaseButton: {
                  style: { width: "48%", margin: "5px" },
                },
              }}
              size={SIZE.mini}
              startEnhancer={() => <BiCartAlt />}
            >
              Add to Cart
            </Button>
            <Button
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => {
                    return {
                      width: "48%",
                      backgroundColor: $theme.colors.primary700,
                    };
                  },
                },
              }}
              onClick={() => openProductDetailsDraware(selectedProduct.id)}
              size={SIZE.mini}
              type="button"
            >
              Details
            </Button>
          </StyledAction>
        </Card>
      </form>
    );
  };
  const getCustomerInfoTable = () => {
    return (
      <>
        <CustomerImg>
          <Image src={selectedCustomer[0]?.image || noImage} alt="user" />
        </CustomerImg>
        <StyledBody>
          <StyledRoot>
            <StyledTable>
              <StyledTableBody>
                <StyledTableBodyRow>
                  <StyledTableBodyCell>Name</StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {selectedCustomer[0]?.name || null}
                  </StyledTableBodyCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <StyledTableBodyCell>Phone Number</StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {(selectedCustomer[0] &&
                      selectedCustomer[0]?.phoneNumber) ||
                      null}
                  </StyledTableBodyCell>
                </StyledTableBodyRow>
              </StyledTableBody>
            </StyledTable>
          </StyledRoot>
        </StyledBody>
      </>
    );
  };
  const getCustomerAddressData = () => {
    return (
      <Card>
        <Row>
          <Col md={6}>
            <h3>Shipping Address :</h3>
            <div style={{ height: "400px", overflowY: "scroll" }}>
              {customerAddresses && customerAddresses.length > 0
                ? customerAddresses.map((address, index) =>
                    address.addressType === "Shipping" ||
                    address.addressType === "Both" ? (
                      <div
                        className={
                          shippingAddressId === address.id
                            ? "address__card address__card--bg"
                            : "address__card"
                        }
                        onClick={() =>
                          onShippingAddress(address.id, address.shippingAreaId)
                        }
                        key={index}
                      >
                        <div className="address__card--info">
                          <div>
                            <p>Full Address: {address?.fullAddress || null}</p>
                            <p>Division: {address?.division || null}</p>
                          </div>
                          <div>
                            <p>City: {address?.city || null}</p>
                            <p>Area: {address?.area || null}</p>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )
                : null}
            </div>
          </Col>
          <Col md={6}>
            <h3>Billing Address :</h3>
            <div style={{ height: "400px", overflowY: "scroll" }}>
              {customerAddresses && customerAddresses.length > 0
                ? customerAddresses.map((address, index) =>
                    address.addressType === "Billing" ||
                    address.addressType === "Both" ? (
                      <div
                        className={
                          billingAddressId === address.id
                            ? "address__card address__card--bg"
                            : "address__card"
                        }
                        onClick={() => onBillingAddress(address.id)}
                        key={index}
                      >
                        <div className="address__card--info">
                          <div>
                            <p>Full Address: {address?.fullAddress || null}</p>
                            <p>Division: {address?.division || null}</p>
                          </div>
                          <div>
                            <p>City: {address?.city || null}</p>
                            <p>Area: {address?.area || null}</p>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )
                : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormFields>
              <FormLabel>Delivery Charge</FormLabel>
              <Input
                value={areaDeliveryCharge}
                onChange={(e) => setAreaDeliveryCharge(e.target.value)}
                type="text"
                size={SIZE.mini}
                placeholder="Delivery Charge"
                name="deliveryCharge"
                //inputRef={register({ required: true })}
              />
            </FormFields>
          </Col>
        </Row>
      </Card>
    );
  };
  const cancelHandler = () => {
    history.push(PARENTORDER);
  };
  return (
    <Grid fluid={true} style={{ marginBottom: "50px" }}>
      <Row style={{ marginBottom: "100px" }}>
        <Col md={12}>
          <Header className="page-header">
            <Col md={4}>
              <Breadcrumb
                parentPageName="Order"
                parentPage={SUBORDER}
                currentPageName="Order Create"
              />
            </Col>
          </Header>
        </Col>

        <Col md={9}>
          <Row>
            <Col md={12}>
              <Card>
                <ProductFilterSelect onSelectedProduct={onSelectedProduct} />
              </Card>
            </Col>
            <Row
              style={{ marginTop: "10px", width: "100%", marginLeft: "0px" }}
            >
              <ErrorValidation errorMessage={errorMessage} />
              {isActiveCustomerForm ? (
                <Col md={6}>
                  <CustomerCreateForm
                    setIsLoadingCustomer={setIsLoadingCustomer}
                    setIsActiveCustomerForm={setIsActiveCustomerForm}
                    setSelectedCustomer={setSelectedCustomer}
                    setIsLoadingCart={setIsLoadingCart}
                    onCancelCustomerForm={onCancelCustomerForm}
                  />
                </Col>
              ) : null}

              {cartItem && cartItem.length > 0 ? (
                <Col md={12} sm={12}>
                  <CartItemTable
                    cartItem={cartItem}
                    onDecrementCartItem={onDecrementCartItem}
                    onIncrementCartItem={onIncrementCartItem}
                    openProductDetailsDraware={openProductDetailsDraware}
                    onRemoveCart={onRemoveCart}
                  />
                </Col>
              ) : null}

              {selectedCustomer.length > 0 && cartItem.length > 0 ? (
                <Col md={8} sm={12} style={{ marginTop: "10px" }}>
                  {customerAddresses && customerAddresses.length > 0
                    ? getCustomerAddressData()
                    : null}
                  {!isActiveAddressForm ? (
                    <Button
                      size={SIZE.compact}
                      onClick={AddNewAddressForm}
                      overrides={{
                        BaseButton: {
                          style: ({ $theme }) => {
                            return {
                              backgroundColor: $theme.colors.primary700,
                              marginTop: "10px",
                            };
                          },
                        },
                      }}
                    >
                      Add New Address
                    </Button>
                  ) : null}
                </Col>
              ) : null}
              {selectedCustomer.length > 0 && isActiveAddressForm ? (
                <Col md={4} style={{ marginTop: "10px" }}>
                  <AddressAddressCreate
                    getSingleAreaData={getSingleAreaData}
                    customerId={selectedCustomer[0].id}
                    setIsLoadingCart={setIsLoadingCart}
                    setShippingAddressId={setShippingAddressId}
                    setBillingAddressId={setBillingAddressId}
                    setIsActiveAddressForm={setIsActiveAddressForm}
                    AddNewAddressForm={AddNewAddressForm}
                  />
                </Col>
              ) : null}
            </Row>
          </Row>
        </Col>
        <Col md={3}>
          <div>
            <Card>
              <Row>
                <Col
                  md={isActiveCustomerForm ? 12 : 8}
                  sm={12}
                  style={{ padding: "0px !important" }}
                >
                  <CustomerSelect onChange={changeCustomerHandler} />
                </Col>
                {!isActiveCustomerForm ? (
                  <Col md={4} sm={12}>
                    <Button
                      overrides={{
                        BaseButton: {
                          style: ({ $theme }) => {
                            return {
                              backgroundColor: isActiveCustomerForm
                                ? $theme.colors.negative500
                                : $theme.colors.primary700,
                              width: "98%",
                              margin: "5px",
                            };
                          },
                        },
                      }}
                      size={SIZE.mini}
                      onClick={onAddNewCustomer}
                    >
                      <small>Add Customer</small>
                    </Button>
                  </Col>
                ) : null}
                {selectedCustomer.length > 0 ? (
                  <Col md={12} sm={12}>
                    {getCustomerInfoTable()}
                  </Col>
                ) : (
                  " "
                )}
              </Row>
            </Card>
          </div>
          <div style={{ marginTop: "10px" }}>
            {selectedProduct && resetCartForm ? addToCartProduct() : null}
          </div>
          <div style={{ marginTop: "10px" }}>
            {cartData && cartItem.length > 0 ? (
              <CartTable
                cartData={cartData}
                couponError={couponError}
                coupon={coupon}
                setCoupon={setCoupon}
                applyCoupon={applyCoupon}
              />
            ) : null}
          </div>
        </Col>
      </Row>
      <ButtonGroup style={{ padding: "15px", zIndex: "0" }}>
        <Button
          size={SIZE.compact}
          disabled={myIsSubmitting}
          type="button"
          kind={KIND.minimal}
          onClick={cancelHandler}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: "50%",
                borderTopLeftRadius: "3px",
                borderTopRightRadius: "3px",
                borderBottomRightRadius: "3px",
                borderBottomLeftRadius: "3px",
                marginRight: "15px",
                color: $theme.colors.red400,
              }),
            },
          }}
        >
          Cancel
        </Button>

        <Button
          size={SIZE.compact}
          type="button"
          disabled={myIsSubmitting}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                width: "50%",
                borderTopLeftRadius: "3px",
                borderTopRightRadius: "3px",
                borderBottomRightRadius: "3px",
                borderBottomLeftRadius: "3px",
              }),
            },
          }}
          onClick={onOrderGenerate}
        >
          Create Order
        </Button>
      </ButtonGroup>

      <PreloaderModal
        onClosePreloaderModal={preloader}
        isOpenPreloaderModalModal={preloader}
      />
    </Grid>
  );
};

export default OrderCerate;
