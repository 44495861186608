import * as React from "react";
import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import { useHistory } from "react-router-dom";
interface IBreadcrumb {
  parentPage?: string;
  subParentPage?: string;
  parentPageName?: string;
  subParentPageName?: string;
  currentPageName?: string;
}
export const Breadcrumb = ({
  parentPage,
  subParentPage,
  parentPageName,
  subParentPageName,
  currentPageName,
}: IBreadcrumb) => {
  const history = useHistory();
  const onParentPage = () => {
    history.push(parentPage);
  };
  const onSubParentPage = () => {
    history.push(subParentPage);
  };

  return (
    <Breadcrumbs
      overrides={{
        Icon: {
          props: {
            overrides: {
              Svg: {
                style: ({ $theme }) => ({
                  display: parentPage || subParentPage ? "block" : "none",
                }),
              },
            },
          },
        },
      }}
    >
      {parentPage ? (
        <StyledLink onClick={onParentPage} href="#">
          {parentPageName || "Parent Page"}
        </StyledLink>
      ) : (
        ""
      )}

      {/* {parentPage && subParentPage ? (
        <StyledLink onClick={onSubParentPage} href="#">
          {subParentPageName || "Sub-Parent Page"} sss
        </StyledLink>
      ) : (
        ""
      )} */}

      <span>{currentPageName || "Current Page"}</span>
    </Breadcrumbs>
  );
};
