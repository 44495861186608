import { WatsonApiInstance } from "@config/api.config";
import { AuthHeader } from "./auth.apiService";

export interface ICartGetAllApiOptions {
  id?: string;
  cartId?: string;
  take?: any;
  page?: number;
  searchTerm?: string;
  customerId?: string;
}

const headers = AuthHeader();
export const CartsService = {
  getAll: (option: ICartGetAllApiOptions) =>
    WatsonApiInstance.get(
      `carts?page=${option.page}&take=${option.take}&searchTerm=${
        option.searchTerm || ""
      }`,
      { headers: AuthHeader() }
    ),
  getAllCartItemByCartId: (option: ICartGetAllApiOptions) =>
    WatsonApiInstance.get(`cart-items?cart=${option.cartId}`, {
      headers: AuthHeader(),
    }),

  getAllCartDataByCustomerId: (option: ICartGetAllApiOptions) =>
    WatsonApiInstance.get(
      `carts/activeCart_By_CustomerId/${option.customerId}`,
      {
        headers: AuthHeader(),
      }
    ),

  create: (newModelData) =>
    WatsonApiInstance.post("carts/", newModelData, { headers: AuthHeader() }),

  couponApply: (newModelData) =>
    WatsonApiInstance.post("carts/coupon", newModelData, { headers: AuthHeader() }),

  createCartItems: (newModelData) =>
    WatsonApiInstance.post("cart-items/", newModelData, {
      headers: AuthHeader(),
    }),
  updateCartItems: (cartItem, id) =>
    WatsonApiInstance.put(`cart-items/${id}`, cartItem, {
      headers: AuthHeader(),
    }),

  getOne: (id) =>
    WatsonApiInstance.get("carts/" + id, { headers: AuthHeader() }),

  deleteOne: (id) =>
    WatsonApiInstance.delete("carts/" + id, { headers: AuthHeader() }),

  deleteCartItem: (id) =>
    WatsonApiInstance.delete(`cart-items/${id}`, { headers: AuthHeader() }),

  update: (editModelData, id) =>
    WatsonApiInstance.put(`carts/${id}`, editModelData, {
      headers: AuthHeader(),
    }),
};
