import Button, { SIZE } from "@components/Button/Button";
import { Col, Row } from "@components/FlexBox/FlexBox";
import { FormFields, FormLabel } from "@components/FormFields/FormFields";
import Input from "@components/Input/Input";
import { Card, StyledBody } from "baseui/card";
import {
  StyledRoot,
  StyledTable,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyRow,
} from "baseui/table-semantic";
import React from "react";
export const CartTable = ({
  cartData,
  couponError,
  coupon,
  setCoupon,
  applyCoupon,
}) => {
  return (
    <Card
      overrides={{ Root: { style: { padding: "20px" } } }}
      title="Order Information"
    >
      {cartData ? (
        <StyledBody>
          <StyledRoot>
            <StyledTable>
              <StyledTableBody>
                <StyledTableBodyRow>
                  <StyledTableBodyCell>Cart Sub Total</StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {cartData?.cartSubTotal || 0}
                  </StyledTableBodyCell>
                </StyledTableBodyRow>
                <StyledTableBodyRow>
                  <StyledTableBodyCell>
                    Without Discount Price
                  </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {cartData?.cartTotalWithoutDiscount || 0}
                  </StyledTableBodyCell>
                </StyledTableBodyRow>

                <StyledTableBodyRow>
                  <StyledTableBodyCell>Total Vat</StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {cartData?.cartTotalVat || 0}
                  </StyledTableBodyCell>
                </StyledTableBodyRow>
                {cartData.couponId ? (
                  <>
                    <StyledTableBodyRow>
                      <StyledTableBodyCell>Coupon Code</StyledTableBodyCell>
                      <StyledTableBodyCell>
                        {cartData?.coupon?.code || null}
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                    <StyledTableBodyRow>
                      <StyledTableBodyCell>Discount Amount</StyledTableBodyCell>
                      <StyledTableBodyCell>
                        {cartData?.coupon?.amount || 0}
                      </StyledTableBodyCell>
                    </StyledTableBodyRow>
                  </>
                ) : null}
                <StyledTableBodyRow>
                  <StyledTableBodyCell>Cart Total</StyledTableBodyCell>
                  <StyledTableBodyCell>
                    {cartData?.cartTotal || null}
                  </StyledTableBodyCell>
                </StyledTableBodyRow>
              </StyledTableBody>
            </StyledTable>
          </StyledRoot>
        </StyledBody>
      ) : null}
      {!cartData.couponId ? (
        <Row>
          {couponError && <li style={{ color: "red" }}>{couponError || ""}</li>}

          <Col md={7}>
            <FormFields>
              <FormLabel>Coupon </FormLabel>
              <Input
                size={SIZE.mini}
                placeholder="Coupon no"
                name="coupon"
                value={coupon}
                onChange={(e: any) => setCoupon(e.target.value)}
              />
            </FormFields>
          </Col>
          <Col md={5}>
            <Button
              overrides={{
                BaseButton: {
                  style: ({ $theme }) => {
                    return {
                      marginTop: "35px",
                    };
                  },
                },
              }}
              size={SIZE.mini}
              onClick={applyCoupon}
              type="button"
              disabled={coupon ? false : true}
            >
              <small>Apply Coupon</small>
            </Button>
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};
