import { SIZE } from "@components/Button/Button";
import { FormFields, FormLabel } from "@components/FormFields/FormFields";
import Input from "@components/Input/Input";
import { AsyncSelect } from "@components/Select/AsyncSelect";
import Select from "@components/Select/Select";
import { Textarea } from "@components/Textarea/Textarea";
import { rsToaster } from "@components/toaster/toaster";
import AreaSelect from "@pages/Select/ScrollSelect/AreaSelect";
import { CustomerService } from "@services/apiServices/Customer.apiService";
import { SupplierService } from "@services/apiServices/Supplier.apiService";
import { Button } from "baseui/button";
import { Card } from "baseui/card";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
interface IAddressAddressCreate {
  customerId: string;
  setIsLoadingCart: Function;
  setShippingAddressId?: Function;
  setBillingAddressId?: Function;
  setIsActiveAddressForm?: Function;
  AddNewAddressForm?: any;
  getSingleAreaData?: any;
}
export const AddressAddressCreate = ({
  customerId,
  setIsLoadingCart,
  setShippingAddressId,
  setBillingAddressId,
  setIsActiveAddressForm,
  AddNewAddressForm,
  getSingleAreaData,
}: IAddressAddressCreate) => {
  const { register, handleSubmit } = useForm();
  const [division, setDivision] = useState([
    { id: "1", name: "Barisal" },
    { id: "2", name: "Chittagong" },
    { id: "3", name: "Dhaka" },
    { id: "4", name: "Khulna" },
    { id: "5", name: "Mymensingh" },
    { id: "6", name: "Rajshahi" },
    { id: "7", name: "Sylhet" },
    { id: "8", name: "Rangpur" },
  ]);
  const [addressType, setAddressType] = useState([
    { id: "1", name: "Shipping" },
    { id: "2", name: "Billing" },
    { id: "3", name: "Both" },
  ]);
  const [selectedDivision, setSelectedDivision] = useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<any[]>([]);
  const [customerArea, setCustomerArea] = useState([]);
  //const [deliveryInfo, setDeliveryInfo] = useState<any[]>([]);
  const [selectedCustomerArea, setSelectedCustomerArea] = useState([]);
  const [value, setValue] = React.useState("");
  const [myIsSubmitting, setMyIsSubmitting] = useState(false);

  const changeAddressHandler = (value) => {
    setSelectedAddress(value.value);
  };
  const changeDivisionHandler = (value) => {
    setSelectedDivision(value.value);
  };
  const onChangeCustomerArea = (selectedData) => {
    setSelectedCustomerArea(selectedData.value);
  };
  useEffect(() => {
    // getAreaData();
    getNewAreaData();
    console.log("deliveryAmount");
  }, [selectedCustomerArea]);

  const getNewAreaData = () => {
    SupplierService.getCustomerNewArea().subscribe(
      (res: any) => {
        setCustomerArea(res.payload);
      },
      (error) => {}
    );
  };

  // const getAreaData = () => {
  //   SupplierService.area().subscribe(
  //     (res: any) => {
  //       setCustomerArea(res.payload);
  //     },
  //     (error) => {}
  //   );
  // };
  const onSubmit = ({ city, fullAddress, name, phoneNumber }) => {
    setMyIsSubmitting(true);

    const addressData = {
      customer: customerId || "",
      addressType: selectedAddress[0].name || "",
      division: selectedDivision[0].name || "",
      city: city || "",
      name: name || "",
      phoneNumber: phoneNumber || "",
      area: selectedCustomerArea[0].name || "",
      //deliveryCharge: deliveryInfo,
      fullAddress: fullAddress || "",
    };
    CustomerService.createAddress(addressData).subscribe(
      (res: any) => {
        const data = res.payload;
        if (data.addressType === "Both") {
          getSingleAreaData(data.shippingArea);
          setShippingAddressId(data.id);
          setBillingAddressId(data.id);
        } else {
          if (data.addressType === "Shipping") {
            setShippingAddressId(data.id);
          } else if (data.addressType === "Billing") {
            setBillingAddressId(data.id);
          }
        }
        setMyIsSubmitting(false);

        setIsLoadingCart(true);
        setIsActiveAddressForm(false);
        rsToaster.success(res.message);
      },
      (error) => {
        setIsActiveAddressForm(false);
        setMyIsSubmitting(false);

        rsToaster.success(error.devMessage);
      }
    );
  };

  return (
    <Card title="Customer Order Address Information">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormFields>
          <FormLabel>Address Type</FormLabel>
          <Select
            size={SIZE.mini}
            options={addressType || ""}
            labelKey="name"
            value={selectedAddress}
            placeholder="Address"
            onChange={changeAddressHandler}
            required
          />
        </FormFields>
        <FormFields>
          <FormLabel>Division</FormLabel>
          <Select
            size={SIZE.mini}
            options={division || ""}
            labelKey="name"
            value={selectedDivision}
            placeholder="Division"
            onChange={changeDivisionHandler}
            required
          />
        </FormFields>
        <FormFields>
          <FormLabel>Name </FormLabel>
          <Input
            size={SIZE.mini}
            placeholder="Name"
            name="name"
            inputRef={register({ required: true })}
          />
        </FormFields>
        <FormFields>
          <FormLabel>Phone Number </FormLabel>
          <Input
            size={SIZE.mini}
            placeholder="Phone Number"
            name="phoneNumber"
            inputRef={register({ required: true })}
          />
        </FormFields>
        <FormFields>
          <FormLabel>City</FormLabel>
          <Input
            size={SIZE.mini}
            placeholder="City"
            name="city"
            inputRef={register({ required: true })}
          />
        </FormFields>
        <FormFields>
          <FormLabel>Area</FormLabel>
          {/* <AsyncSelect
            getOptions={customerArea}
            onSelectedOptions={selectedCustomerArea}
            onChangeValue={onChangeCustomerArea}
            setLabel={"city"}
            placeholder="Search customer Area"
          /> */}
          <AreaSelect
            // setSelectedAreaDeliveryCharge={setDeliveryInfo}
            setSelectedArea={setSelectedCustomerArea}
          />
        </FormFields>
        <FormFields>
          <FormLabel>Full Address</FormLabel>
          <Textarea
            size={SIZE.mini}
            placeholder="Full Address"
            name="fullAddress"
            inputRef={register({ required: true })}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </FormFields>
        {/* <FormFields>
          <FormLabel>Delivery Charge</FormLabel>
          <Input
            value={deliveryInfo}
            onChange={(e) => setDeliveryInfo(e.target.value)}
            type="text"
            size={SIZE.mini}
            placeholder="Delivery Charge"
            name="deliveryCharge"
            inputRef={register({ required: true })}
          />
        </FormFields> */}
        <div style={{ display: "flex" }}>
          <Button
            overrides={{
              BaseButton: {
                style: { width: "48%", margin: "5px" },
              },
            }}
            size={SIZE.mini}
            disabled={myIsSubmitting}
          >
            Create Address
          </Button>
          <Button
            type="button"
            size={SIZE.mini}
            onClick={AddNewAddressForm}
            overrides={{
              BaseButton: {
                style: ({ $theme }) => {
                  return {
                    backgroundColor: $theme.colors.negative500,
                    width: "48%",
                    margin: "5px",
                  };
                },
              },
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Card>
  );
};
