import { WatsonApiInstance } from "@config/api.config";
import { AuthHeader } from "./auth.apiService";

export interface IProductGetAllApiOptions {
  id?: string;
  take?: any;
  page?: number;
  searchTerm?: string;
  supplier?: string;
  brand?: string;
  department?: string;
  category?: string;
  subCategory?: string;
}

const headers = AuthHeader();
export const ProductService = {
  getAll: (option: IProductGetAllApiOptions) =>
    WatsonApiInstance.get(
      `products?supplier=${option.supplier || ""}&brand=${
        option.brand || ""
      }&department=${option.department || ""}&category=${
        option.category || ""
      }&subCategory=${option.subCategory || ""}&page=${option.page}&take=${
        option.take
      }&searchTerm=${option.searchTerm || ""}`,
      { headers: AuthHeader() }
    ),

  getProductAll: (option: IProductGetAllApiOptions) =>
    WatsonApiInstance.get(
      `products/names?supplier=${option.supplier || ""}&brand=${
        option.brand || ""
      }&department=${option.department || ""}&category=${
        option.category || ""
      }&subCategory=${option.subCategory || ""}&page=${option.page}&take=${
        option.take
      }&searchTerm=${option.searchTerm || ""}`,
      { headers: AuthHeader() }
    ),

  create: (newModelData) =>
    WatsonApiInstance.post("products/create-product", newModelData, {
      headers: AuthHeader(),
    }),

  // create: (newModelData) =>
  // WatsonApiInstance.post("products/create-product", newModelData, {
  //   headers,
  // }),

  getOne: (id) =>
    WatsonApiInstance.get("products/" + id, { headers: AuthHeader() }),

  getVariantOptionsOne: (id) =>
    WatsonApiInstance.get("variantOptions/" + id, { headers: AuthHeader() }),

  deleteOne: (id) =>
    WatsonApiInstance.delete("products/" + id, { headers: AuthHeader() }),

  update: (editModelData, id) =>
    WatsonApiInstance.put(`products/${id}`, editModelData, {
      headers: AuthHeader(),
    }),

  deleteImageOne: (id) =>
    WatsonApiInstance.delete("productImages/" + id, { headers: AuthHeader() }),
  deleteVariantOptionsOne: (id) =>
    WatsonApiInstance.delete("variantOptions/" + id, { headers: AuthHeader() }),
  productImageCreate: (newModelData) =>
    WatsonApiInstance.post("productImages", newModelData, {
      headers: AuthHeader(),
    }),

  variantOptions: (newModelData) =>
    WatsonApiInstance.post("variantOptions", newModelData, {
      headers: AuthHeader(),
    }),
};
