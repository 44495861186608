import { rsToaster } from "@components/toaster/toaster";
import { CustomerService } from "@services/apiServices/Customer.apiService";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { MenuList } from "../../../@components/Select/InfiniteScroll/MenuList";

interface IModelPagination {
  currentPage: number;
  take: number;
  totalPage: number;
  totalData: number;
}
interface ICustomerSelect {
  loading?: Function;
  onChange?: Function;
}

function CustomerSelect({ loading, onChange }: ICustomerSelect) {
  const [response, setResponse] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [
    modelPagination,
    setModelPagination,
  ] = React.useState<IModelPagination>({
    currentPage: 0,
    take: 10,
    totalPage: 0,
    totalData: 0,
  });

  const [perPageTake, setPerPageTake] = useState([{ title: "10", value: 10 }]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (isLoading) {
      getCustomerData();
    }
  }, [isLoading]);

  const mockService = () => {
    setModelPagination({
      ...modelPagination,
      currentPage: modelPagination.currentPage + 1,
    });
    setIsLoading(true);
  };

  const getCustomerData = () => {
    CustomerService.getAll({
      page: modelPagination.currentPage || 1,
      take: perPageTake[0].value,
      searchTerm,
    }).subscribe(
      (res: any) => {
        let CustomerData = res.payload;
        setModelPagination({
          ...modelPagination,
          totalData: res.total,
          take: res.take,
          currentPage: res.page,
          totalPage: Math.ceil(res.total / res.take),
        });
        setResponse([...response, ...CustomerData]);
        setIsLoading(false);
      },
      (error) => {
        rsToaster.error(`${error.message}`);
        setIsLoading(false);
      }
    );
  };

  const onChangeSelectOption = (value) => {
    if (!value) {
      onChange([]);
      return;
    }
    const data = value.payload;
    const dd = [
      {
        id: data?.id,
        name: data?.name,
        image: data?.image,
        phoneNumber: data?.user.phoneNumber,
      },
    ];
    onChange(dd);
  };

  const handleInputChange = (newValue) => {
    setResponse([]);
    const inputValue = newValue;
    setSearchTerm(inputValue);
    setModelPagination({
      currentPage: 1,
      take: 10,
      totalPage: 0,
      totalData: 0,
    });
    setIsLoading(true);
  };
  const onOptions = response.map((item) => {
    return {
      value: item.name || item?.user?.phoneNumber || "",
      label: item.name || item?.user?.phoneNumber || "",
      payload: {
        ...item,
      },
    };
  });

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      options={onOptions}
      hasNextPage={true}
      isNextPageLoading={isLoading}
      loadNextPage={mockService}
      itemSize={() => 50}
      components={{
        MenuList,
      }}
      onChange={onChangeSelectOption}
      onInputChange={handleInputChange}
      totalItem={modelPagination.totalData || 0}
      currentTotal={response.length || 0}
      isClearable
    />
  );
}

export default CustomerSelect;
