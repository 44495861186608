import { WatsonApiInstance } from "@config/api.config";
import { LocalStorageService } from "@services/utils/localStorage.utilservice";
import { isExpired } from "react-jwt";

type LoginPayload = {
  phoneNumber: String;
  password: String;
};
type SignupPayload = {
  phoneNumber: String;
  password: String;
  name: String;
  // userType: String;
};
type RefreshToken = {
  refreshToken: String;
};

export const AuthService = {
  login: (loginPayload: LoginPayload) =>
    WatsonApiInstance.post("auth/panel_login", loginPayload),

  refreshToken: (refreshToken: RefreshToken) =>
    WatsonApiInstance.post("auth/refresh-token", refreshToken),

  signup: (signupPayload: SignupPayload) =>
    WatsonApiInstance.post("auth/panel_register", signupPayload),
};
export const removeToken = () => {
  LocalStorageService.delete("ajke_accessToken");
  LocalStorageService.delete("refreshToken");
};
export const loginRedirect = () => {
  removeToken();
  window.location.href = "login";
  return;
};
export const AuthHeader = () => {
  const accessToken = LocalStorageService.get("ajke_accessToken");
  const refreshToken = LocalStorageService.get("refreshToken");

  const accessTokenExpired = isExpired(accessToken);
  const refreshTokenExpired = isExpired(refreshToken);

  // console.log("accessTokenExpired:", accessTokenExpired);
  // console.log("accessToken:", decodeToken(accessToken));
  // console.log("refreshTokenExpired:", refreshTokenExpired);
  // console.log("refreshToken:", refreshToken);

  if (accessToken && !accessTokenExpired) {
    return {
      Authorization: "Bearer " + accessToken,
    };
  } else if (
    accessToken &&
    accessTokenExpired &&
    refreshToken &&
    !refreshTokenExpired
  ) {
    removeToken();
    AuthService.refreshToken({ refreshToken }).subscribe(
      (res: any) => {
        LocalStorageService.set(
          "ajke_accessToken",
          res?.payload?.accessToken || accessToken
        );
        LocalStorageService.set(
          "refreshToken",
          res?.payload?.refreshToken || refreshToken
        );
      },
      (error) => {
        loginRedirect();
      }
    );
  } else {
    removeToken();
  }
};
