import { ProductService } from "@services/apiServices/product.apiService";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import Select from "react-select";
import { ProductListDropDown } from "../../../@components/Select/InfiniteScroll/ProductListDropDown";

interface IModelPagination {
  currentPage: number;
  take: number;
  totalPage: number;
  totalData: number;
}
interface IProductSelect {
  department?: any;
  category?: any;
  subCategory?: any;
  onSelectedProduct?: Function;
}

const ProductSelect = forwardRef(
  (
    {
      department = [],
      category = [],
      subCategory = [],
      onSelectedProduct,
    }: IProductSelect,
    ref
  ) => {
    const [response, setResponse] = React.useState([]);
    const [modelPagination, setModelPagination] =
      React.useState<IModelPagination>({
        currentPage: 0,
        take: 20,
        totalPage: 0,
        totalData: 0,
      });

    const [perPageTake, setPerPageTake] = useState([
      { title: "20", value: 20 },
    ]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isProductLoading, setIsProductLoading] = useState(false);

    useEffect(() => {
      if (isProductLoading) {
        getProductData();
      }
    }, [isProductLoading]);

    useImperativeHandle(ref, () => ({
      restProductData() {
        console.log("doing");

        setResponse([]);
        setModelPagination({
          currentPage: 0,
          take: 10,
          totalPage: 0,
          totalData: 0,
        });
      },
    }));

    const mockService = () => {
      setModelPagination({
        ...modelPagination,
        currentPage: modelPagination.currentPage + 1,
      });
      setIsProductLoading(true);
    };

    const getProductData = () => {
      ProductService.getProductAll({
        page: modelPagination.currentPage || 1,
        take: perPageTake[0].value,
        department: department[0]?.id || "",
        category: category[0]?.id || "",
        subCategory: subCategory[0]?.id || "",
        searchTerm,
      }).subscribe(
        (res: any) => {
          let productData = res.payload;
          setModelPagination({
            ...modelPagination,
            totalData: res.total,
            take: res.take,
            currentPage: res.page,
            totalPage: Math.ceil(res.total / res.take),
          });
          setResponse([...response, ...productData]);
          setIsProductLoading(false);
        },
        (error) => {
          setIsProductLoading(false);
        }
      );
    };
    const handleInputChange = (newValue) => {
      setResponse([]);
      const inputValue = newValue;
      setSearchTerm(inputValue);
      setModelPagination({
        currentPage: 1,
        take: 10,
        totalPage: 0,
        totalData: 0,
      });
      setIsProductLoading(true);
    };

    const onChangeSelectOption = (value) => {
      if (!value) {
        onSelectedProduct([]);
        return;
      }
      const data = value.payload;
      const dd = [
        {
          id: data?.id,
          name: data?.name,
          thumb: data.thumb,
          stock: data.stock,
          variantOptions: data?.variantOptions,
          variantName: data?.variantName,
        },
      ];
      onSelectedProduct(dd);
    };
    const onOptions = response.map((item) => {
      return {
        value: item.name,
        label: item.name,
        payload: {
          ...item,
        },
      };
    });
    return (
      <Select
        className="basic-single"
        classNamePrefix="select"
        options={onOptions}
        hasNextPage={true}
        isNextPageLoading={isProductLoading}
        loadNextPage={mockService}
        itemSize={() => 50}
        components={{
          MenuList: ProductListDropDown,
        }}
        onChange={onChangeSelectOption}
        onInputChange={handleInputChange}
        totalItem={modelPagination.totalData || 0}
        currentTotal={response.length || 0}
        isClearable
      />
    );
  }
);

export default ProductSelect;
