// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = "/";
export const PRODUCTS = "/products";
export const CREATEPRODUCT = "/create-product";
export const DUPLICATEPRODUCT = "/duplicate-product/:id";
export const UPDATEPRODUCT = "/update-products/:id";
export const PRODUCTPRIORITY = "/product-priority";

// export const RESELLER = "/reseller";
// export const RESELLERDETAILS = "/reseller-details/:id";

export const SUPPLIERS = "/suppliers";
export const WITHDRAWREQUEST = "/withdraw-request";

export const LOGIN = "/login";
export const Registration = "/registration";
export const FORGOTPASSWORD = "/forget-password";
export const LOGOUT = "/logout";
export const BRAND = "/brand";
export const BRANDR = "/brand-redux";
export const DEPARTMENT = "/department";
export const CATEGORY = "/category";
export const SUBCATEGORY = "/sub-category";
export const SUBCATEGORYPRIORITY = "/sub-category-priority";
export const PARENTORDER = "/orders";
export const SUBORDER = "/sub-orders/:id";
export const ORDERCREATE = "/order-create";
export const ORDERDETAILS = "/order/:id";
export const ORDERITEMSDELIVERED = "/order-items-delivered";
export const ORDERITEMSRETURNED = "/order-items-returned";
export const STAFF = "/staff";
export const EMPLOYEES = "/employees";
export const WAREHOUSEOPERATOR = "/warehouse-operator";
export const PRODUCTREQUISITION = "/product-requisitions";
export const INVOICE = "/invoices";
export const SUPPLIERDETAILS = "/supplier-details/:id";
export const PRODUCTREQUISITIONPRINT = "/product-requisition/:id";
export const INVOICEPRINT = "/invoice-print/:id";

export const CAMPAIGNCREATE = "/campaign-create";
export const CAMPAIGNEDIT = "/campaign-edit/:id";
export const CAMPAIGNS = "/campaigns";
export const CONTACTS = "/contacts";
export const CUSTOMERS = "/customers";
export const CUSTOMERDETAILS = "/customer-details/:id";
export const TOPSUPPLERS = "/top-suppliers";

export const LIVE = "/live";
export const LIVECREATE = "/live-create";
export const LIVEEDIT = "/live-edit/:id";

export const FEATUREDPRODUCT = "/featured-product";
export const FEATUREDPRODUCTCREATE = "/featured-product-create";
export const FEATUREDPRODUCTEDIT = "/featured-product-edit/:id";

export const ADVANCEPAID = "/advance-payment";
export const PROFILE = "/profile";
export const COUPON = "/coupon";

export const CMS = "/cms";
export const CMSCREATE = "/cms-create";
export const CMSEDIT = "/cms-edit/:id";

// export const ORDERS = '/orders';
// export const CUSTOMERS = '/customers';
// export const COUPONS = '/coupons';
// export const SETTINGS = '/settings';
// export const STAFF_MEMBERS = '/staff-members';
// export const SITE_SETTINGS = '/site-settings';
// **************** ROUTE CONSTANT END **************************
export const SETTINGS = "/settings";

export const CURRENCY = "$";
