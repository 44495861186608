import { SupplierService } from "@services/apiServices/Supplier.apiService";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { MenuList } from "../../../@components/Select/InfiniteScroll/MenuList";

interface IModelPagination {
  currentPage: number;
  take: number;
  totalPage: number;
  totalData: number;
}
interface IAreaSelect {
  loading?: Function;
  setSelectedArea?: Function;
  // setSelectedAreaDeliveryCharge?:any;
  selectedArea?: any;
  department?: any;
  isLoadingArea?: boolean;
}

function AreaSelect({ setSelectedArea, isLoadingArea }: IAreaSelect) {
  const [response, setResponse] = React.useState([]);
  const [modelData, setModelData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [modelPagination, setModelPagination] =
    React.useState<IModelPagination>({
      currentPage: 0,
      take: 10,
      totalPage: 0,
      totalData: 0,
    });

  const [perPageTake, setPerPageTake] = useState([{ title: "10", value: 10 }]);

  useEffect(() => {
    //getAreaData();
    getNewAreaData();
  }, [isLoadingArea]);

  const mockService = () => {
    console.log("ddd");
  };

  // const getAreaData = () => {
  //   SupplierService.area().subscribe(
  //     (res: any) => {
  //       setModelData(res.payload);
  //     },
  //     (error) => {}
  //   );
  // };

  const getNewAreaData = () => {
    SupplierService.getCustomerNewArea().subscribe(
      (res: any) => {
        setModelData(res.payload);
      },
      (error) => {}
    );
  };
  const onChangeSelectOption = (value) => {
    if (!value) {
      return;
    }
    const data = value?.payload;
    //setSelectedAreaDeliveryCharge(value?.payload.deliveryCharge.amount)
    const dd = [data];
    setSelectedArea(dd);
  };

  const onOptions = modelData.map((item) => {
    return {
      value: item.name,
      label: item.name,
      payload: {
        ...item,
      },
    };
  });
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      options={onOptions}
      hasNextPage={true}
      isNextPageLoading={isLoading}
      loadNextPage={mockService}
      itemSize={() => 50}
      components={{
        MenuList,
      }}
      onChange={onChangeSelectOption}
      totalItem={modelData.length || 0}
      currentTotal={response.length || 0}
      isClearable
    />
  );
}

export default AreaSelect;
